import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { initializeAnalytics } from "./analytics";
import { initializeTagManager } from "./tagmanager";
import { Helmet } from "react-helmet";

const isProduction = process.env.REACT_APP_ROBOTS_POLICY === "all";

if (isProduction) initializeAnalytics();
if (isProduction) initializeTagManager();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Helmet>
      {isProduction ? (
        <script async src="https://s.inlook.is/s/4pNR5A/inlk.js"></script>
      ) : null}
    </Helmet>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
