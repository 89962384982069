import logo from "../../assets/logo.webp";
import phone from "../../assets/phone.svg";
import Button from "../Button";

export default function Nav() {
  const links = (
    <div className="d-flex align-items-center gap-4">
      <u>
        <a className="d-flex" href="#funeral">
          Funerali
        </a>
      </u>
      <u>
        <a className="d-flex gap-1" href="#cremation">
          <span className="d-none d-md-block">Funerale e</span> Cremazione
        </a>
      </u>
      <u>
        <a className="d-flex" href="#contract">
          Contratto in Vita
        </a>
      </u>
    </div>
  );

  return (
    <nav className="pb-2 pb-lg-0">
      <div className="container">
        <div className="content">
          <div className="d-flex justify-content-between pt-3 pt-md-3 pb-3">
            <div className="logo mt-auto m-lg-0">
              <img src={logo} alt="logo parisi mario onoranze funebri" />
            </div>
            <div className="d-none d-lg-flex">{links}</div>
            <div className="d-flex flex-column align-items-center">
              {/* <small>
                <i>Pronto Intervento</i>
              </small> */}
              <Button
                onClickLink={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                text={`348 419 16 93 (24h)`}
                icon={phone}
                className="tag-manager-call"
              />
            </div>
          </div>
          <div className="d-flex justify-content-center d-lg-none mt-2">
            {links}
          </div>
        </div>
      </div>
    </nav>
  );
}
