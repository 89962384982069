import servicesImg from "../../../assets/services.webp";

export default function Services() {
  const services = [
    {
      title: "Funerale",
      text: "Organizzazione completa del funerale, non dovrai pensare a niente. Sei in buone mani.",
      link: {
        text: "Scopri di più",
        to: "#funeral",
      },
    },
    {
      title: "Cremazione con Funerale",
      text: "Organizzazione della cremazione, gestione di tutti i processi.",
      link: {
        text: "Scopri di più",
        to: "#cremation",
      },
    },
    {
      title: "Contratto in vita",
      text: "Pianifica in anticipo per non lasciare alcun pensiero ai tuoi familiari.",
      link: {
        text: "Scopri di più",
        to: "#contract",
      },
    },
    {
      title: "Molto altro",
      text: "Esserti vicino in questo momento difficile è la nostra priorità, la nostra azienda saprà personalizzare un servizio per le tue necessità",
      link: {
        text: "Chiama",
        to: "#contact",
      },
    },
  ];

  return (
    <section id="services" className="pt-5 pt-md-0">
      <div className="oval d-none d-md-block"></div>

      <div
        className="bg d-none d-md-block"
        style={{ backgroundImage: `url(${servicesImg})` }}
      ></div>
      <div className="container">
        <div className="content">
          <div className="text-center">
            <span className="h2">I nostri servizi</span>
          </div>
          <br />
          <br />
          <br />
          <div className="d-flex flex-wrap justify-content-center gap-5">
            {services.map((service, key) => {
              return (
                <div style={{ width: "275px" }} key={"service_" + key}>
                  <div className="h5">
                    <span>
                      <strong>{service.title}</strong>
                    </span>
                  </div>
                  <div className="line"></div>
                  <br />
                  <p>{service.text}</p>
                  <p>
                    <u>
                      <a href={service.link.to}>{service.link.text}</a>
                    </u>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="oval"></div>
    </section>
  );
}
