import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home/Home";
import { useEffect } from "react";

export default function Router() {
  return (
    <Routes>
      <Route path="*" index element={<Home />} />
    </Routes>
  );
}

export function scrollToTop() {
  window.scrollTo({ top: 0 });
}

export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return <></>;
}
