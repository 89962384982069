export default function Footer() {
  return (
    <footer className="py-4">
      <div className="container">
        <div className="content text-center">
          <div className="d-flex justify-content-center">Parisi Mario SNC</div>
          <div className="d-flex justify-content-center">
            {process.env.REACT_APP_PHONE_NUMBER} - 095938811 - Sede principale:
            via Gioberti, 11, Giarre CT
          </div>
        </div>
      </div>
    </footer>
  );
}
