import { useEffect, useState } from "react";
const COOKIES_ACCEPTED_KEY = "COOKIES_ACCEPTED_m96tweq5432c";

export default function CookiesBanner() {
  const [cookiesAccepted, setCookiesAccepted] = useState(true);

  useEffect(() => {
    setCookiesAccepted(
      window.localStorage.getItem(COOKIES_ACCEPTED_KEY) ? true : false
    );
  }, []);

  if (cookiesAccepted) return <></>;

  return (
    <div className="cookies-banner d-flex align-items-center justify-content-center gap-4">
      <span>
        Questo sito utilizza solo Cookies tecnici e di analisi del traffico.
      </span>

      <button
        onClick={() => {
          window.localStorage.setItem(COOKIES_ACCEPTED_KEY, "true");
          setCookiesAccepted(true);
        }}
      >
        OK
      </button>
    </div>
  );
}
