import "./styles/styles.scss";
import Nav from "./components/Nav/Nav";
import Router from "./Router";
import Footer from "./components/Footer/Footer";
import CookiesBanner from "./components/CookiesBanner/CookiesBanner";

function App() {
  return (
    <>
      <Nav />
      <Router />
      <Footer />
      <CookiesBanner />
    </>
  );
}

export default App;
