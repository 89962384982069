import Button from "../../../components/Button";
import phone from "../../../assets/phone.svg";

interface Props {
  id?: string;
  dark?: boolean;
  heroTitle?: string;
  heroSubtitle?: string;
  steps?: { text: string; icon: any }[];
  mainTitle?: string;
  text1?: string | any;
  text2?: string | any;
  text3?: string | any;
  image?: any;
}

export default function Pre(props: Props) {
  const halfPointsLenght = props.steps ? Math.ceil(props.steps.length / 2) : 0;

  return (
    <section id="pre" className={`py-5 ${props.dark ? "dark" : ""}`}>
      <div id={props.id}></div>
      <div className="container">
        <div className="content">
          <div className="d-md-flex gap-5">
            <hr className="w100 d-none d-md-block" />
            <div className="px-3">
              <h3 className="nowrap text-center h1">{props.heroTitle}</h3>
              <br />
              <p className="text-center">{props.heroSubtitle}</p>
            </div>
            <hr className="w100 d-none d-md-block" />
          </div>
          {props.steps ? (
            <>
              <br />
              <div className="keypoints">
                <div className="d-md-flex justify-content-around px-5">
                  {props.steps?.slice(0, halfPointsLenght).map((step) => {
                    return (
                      <div
                        key={"step_" + Math.random()}
                        className="d-flex gap-2 my-1 justify-content-between px-2"
                      >
                        <div>
                          <img src={step.icon} alt="icon funeral" />
                        </div>
                        <div className="nowrap">{step.text}</div>
                      </div>
                    );
                  })}
                </div>
                <div className="d-md-flex justify-content-around px-5">
                  {props.steps
                    ?.slice(halfPointsLenght, props.steps.length)
                    .map((step) => {
                      return (
                        <div
                          key={"step_" + Math.random()}
                          className="d-flex gap-2 my-1 justify-content-between px-2"
                        >
                          <div>
                            <img src={step.icon} alt="icon funeral" />
                          </div>
                          <div className="nowrap">{step.text}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          ) : null}
          <br />
          <br />
          <div className="row px-3">
            <div className="col-md">
              <div className="pt-5">
                <span className="h4">
                  <strong>{props.mainTitle}</strong>
                </span>
                <br />
                <br />
                <p>{props.text1}</p>
                <p>{props.text2}</p>
                <p>{props.text3}</p>
                <br />
                <Button
                  className="w100 d-md-none tag-manager-call"
                  onClickLink={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                  text="Scopri di Più su Questo Servizio"
                  icon={phone}
                />
                <Button
                  className="d-none d-md-block"
                  icon={phone}
                  text="Scopri di Più su Questo Servizio"
                  onClickLink={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_PHONE_NUMBER}&text=Salve, vorrei sapere di più sui vostri servizi.`}
                />
              </div>
            </div>
            <div className="col-md">
              <div className="h100 d-flex align-items-center justify-content-center p-2 pt-5 p-md-5">
                <div className="img-canvas">
                  <img
                    className="w100"
                    src={props.image}
                    alt="onoranze funebri mario parisi"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
