import Button from "../../../components/Button";
import phone from "../../../assets/phone.svg";
import chat from "../../../assets/chat.svg";
import boss from "../../../assets/boss.webp";

export default function Where() {
  return (
    <section id="where" className="pb-5 pt-2">
      <div className="container">
        <div className="content">
          <div id="contact"></div>
          <div className="row px-3">
            <div className="col-md">
              <div className="pt-5">
                <span className="h2">
                  <strong>Contattaci</strong>
                </span>
                <br />
                <br />
                <p>
                  Chiamaci o inviaci un messaggio, saremo lieti di rispondere
                  alle tue domande e proporti un preventivo per i nostri
                  servizi. Grazie.
                </p>

                <ul>
                  <li>
                    Telefono:{" "}
                    <a
                      className="tag-manager-call"
                      href={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                    >
                      <u>{process.env.REACT_APP_PHONE_NUMBER}</u>
                    </a>
                  </li>
                  <li>
                    Fisso:{" "}
                    <a href="tel:095938811">
                      <u>095938811</u>
                    </a>
                  </li>
                  <li>
                    Whatsapp:{" "}
                    <a
                      href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_PHONE_NUMBER}&text=Salve, vorrei sapere di più sui vostri servizi.`}
                    >
                      <u>{process.env.REACT_APP_PHONE_NUMBER}</u>
                    </a>
                  </li>
                  <li>
                    Email:{" "}
                    <a href="mailto:info@parisimario.com">
                      <u>info@parisimario.com</u>
                    </a>
                  </li>
                  <li>Sede principale: via Gioberti, 11, Giarre CT</li>
                </ul>
                <br />
                <div className="d-none d-md-flex gap-2">
                  <Button
                    onClickLink={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                    text="Chiama Subito (24h)"
                    icon={phone}
                    className="tag-manager-call"
                  />
                  <Button
                    onClickLink={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_PHONE_NUMBER}&text=Salve, vorrei sapere di più sui vostri servizi.`}
                    secondary
                    icon={chat}
                    text="Invia un Messaggio"
                  />
                </div>
                <div className="d-md-none d-flex flex-column gap-2">
                  <Button
                    onClickLink={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}
                    text="Chiama Subito (24h)"
                    icon={phone}
                    className="tag-manager-call"
                  />
                  <Button
                    onClickLink={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_PHONE_NUMBER}&text=Salve, vorrei sapere di più sui vostri servizi.`}
                    secondary
                    icon={chat}
                    text="Invia un Messaggio"
                  />
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="h100 d-flex align-items-center justify-content-center p-5">
                <div className="img-canvas">
                  <img
                    className="w100"
                    src={boss}
                    alt="onoranze funebri mario parisi"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
