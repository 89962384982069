import contract from "../../../assets/contract.svg";
import value from "../../../assets/value.svg";
import urn from "../../../assets/urn.svg";
import coffin from "../../../assets/coffin.svg";
import love from "../../../assets/love.svg";

export default function Strength() {
  const blocks = [
    {
      img: contract,
      title: "Burocrazia",
      text: "Ci occuperemo di tutta la burocrazia, anche nei casi più complessi",
      marginTop: "0px",
    },
    {
      img: value,
      title: "Intensità",
      text: "Ci assicureremo che l'ultimo saluto al tuo caro sia dignitoso e sentito",
      marginTop: "25px",
    },
    {
      img: urn,
      title: "Cremazione",
      text: "Quando richiesto, gestiremo tutto il percorso che porta alla cremazione e al contenimento o dispersione delle ceneri",
      marginTop: "50px",
    },
    {
      img: coffin,
      title: "Organizzazione",
      text: "Organizzeremo il funerale nei minimi dettagli, lasciandoti con un pensiero in meno in un momento delicato",
      marginTop: "25px",
    },
    {
      img: love,
      title: "Valore",
      text: "Il supporto del nostro personale va oltre il funerale, il valore umano per noi è fondamentale",
      marginTop: "0px",
    },
  ];

  return (
    <section id="strength">
      <div className="container">
        <div className="content">
          <div className="d-md-flex gap-5">
            <hr className="w100 d-none d-md-block" />
            <div className="px-3">
              <h3 className="text-center">
                Ti staremo vicini durante tutto il percorso
              </h3>
              <br />
              <p className="text-center">
                Ci occuperemo di tutti gli step che compongono il funerale, tu
                non dovrai pensare a nulla, il nostro personale ti accompagnerà
                con professionalità e riservatezza.
              </p>
            </div>
            <hr className="w100 d-none d-md-block" />
          </div>
          <br className="d-sm-none" />
          <br className="d-sm-none" />
          <div className="points d-flex justify-content-center flex-wrap gap-3">
            {blocks.map((block, key) => {
              return (
                <div
                  style={{ width: "200px" }}
                  className={`d-flex flex-column align-items-center`}
                  key={"block_" + key}
                >
                  <div
                    className="d-none d-xl-block"
                    style={{ marginTop: block.marginTop }}
                  ></div>
                  <div className="img-container">
                    <img src={block.img} alt="funeral" />
                  </div>
                  <br />
                  <span>
                    <strong>{block.title}</strong>
                  </span>
                  <p className="text-center mt-2">{block.text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
