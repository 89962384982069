import chat from "../../../assets/chat.svg";
import star from "../../../assets/star.svg";

export default function Reviews() {
  const stars = (
    <div className="d-flex gap-2 mb-3">
      <img style={{ height: "20px" }} src={star} alt="star" />
      <img style={{ height: "20px" }} src={star} alt="star" />
      <img style={{ height: "20px" }} src={star} alt="star" />
      <img style={{ height: "20px" }} src={star} alt="star" />
      <img style={{ height: "20px" }} src={star} alt="star" />
    </div>
  );

  return (
    <section id="reviews" className="py-5">
      <div className="container">
        <div className="content">
          <div className="d-flex justify-content-center">
            <div className="chat-icon">
              <img src={chat} alt="chat icon" />
            </div>
          </div>
          <br />
          <div className="d-md-flex gap-5">
            <hr className="w100 d-none d-md-block" />
            <div className="d-md-flex gap-5">
              <div className="px-3">
                <h3 className="nowrap text-center">Cosa dicono di noi</h3>
                <p className="text-center">
                  Siamo stabilmente classificati tra le prime imprese a Catania
                  per <strong>reputazione:</strong> attenzione al cliente,
                  comunicazione trasparente, valorizzazione costante del nostro
                  gruppo di lavoro
                </p>
                <br />
              </div>
            </div>
            <hr className="w100 d-none d-md-block" />
          </div>
          <div className="row">
            <div className="col-md mx-2 m-md-none">
              {stars}
              <i>
                <small>
                  L'agenzia funebre Parisi Mario, è caratterizzata da uno staff
                  che mi è stato accanto dal primo momento fino all'ultimo, con
                  massima professionalità, cordialità e soprattutto umanità. Si
                  è fatta carico totalmente dell'organizzazione del funerale e
                  Cremazione di mio papà, gestendo le non poche difficoltà che
                  si sono presentate. Nonostante la lontananza, (io mi trovo in
                  provincia di Agrigento) hanno gestito il tutto con estrema
                  professionalità, mettendosi a disposizione, risolvendo tutte
                  le controversie burocratiche, (mio papà è venuto a mancare di
                  venerdì, e l'indomani sarebbe subentrato il weekend.) Hanno
                  organizzato al meglio il funerale che si è svolto in un altro
                  paese rispetto a quello in cui è avvenuto il decesso, e
                  gestendo al meglio anche delle controversie familiari. Mi sono
                  stati vicino fino al momento della Cremazione avvenuta a Delia
                  e inoltre hanno trattenuto il feretro in agenzia poiché il
                  forno crematorio di Sabato chiudeva prima. Infine si tratta di
                  un agenzia funebre attenta ai bisogni e alle richieste della
                  famiglia, mettendosi a totale disposizione di quest'ultima.
                  Discreti, gentili, umani, cordiali, e professionali, tutte
                  qualità non facili da trovare oggigiorno, nessuna pecca.
                  <br />
                  Consigliata al 100%.
                </small>
              </i>
              <br />
              <br />
              Da <strong>Bernadette N.</strong>
              <br />
              <small>Recensioni Google</small>
            </div>
            <hr className="d-md-none my-4" />
            <div className="col-md mx-2 m-md-none">
              {stars}
              <i>
                Vivo in Germania! Lì il servizio di cremazione pensato da mamma,
                è civilmente utilizzato. Ma in Sicilia? E a Barcellona Pozzo di
                Gotto? Invece è bastata una telefonata e una serie di cuscini di
                azioni, parole, premure confortevoli si sono materializzate
                attorno alla famiglia al gentile suoni delle parole "Tranquillo,
                ci pensiamo noi"! Non mi aspettavo tanta garbata
                professionalità. Grazie. Sentitamente grazie.
              </i>
              <br />
              <br />
              Da <strong>Marco C.</strong>
              <br />
              <small>Recensioni Google</small>
            </div>
            <hr className="d-md-none my-4" />
            <div className="col-md mx-2 m-md-none">
              {stars}
              <i>
                Ho trovato tanta cordialità, disponibilità e cortesia, hanno
                pensato a tutto loro, hanno reso meno doloroso quel momento già
                così triste. Non posso che ringraziare tutti, dal titolare della
                ditta, sempre cordialissimo e gentile, a Giuseppe, la persona
                che si è occupata di tutto. Grazie.
              </i>
              <br />
              <br />
              Da <strong>Peppe M.</strong>
              <br />
              <small>Recensioni Google</small>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
